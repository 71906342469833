<template>
  <v-card class="basic-modal" elevation="8">
    <v-card-title class="d-flex justify-space-between">
      <h5
        :class="`${modalOptions.color ? modalOptions.color : 'primary'}--text`"
      >
        <v-icon
          size="24"
          class="mr-4"
          v-if="modalOptions.icon"
          :color="modalOptions.color ? modalOptions.color : 'primary'"
          >{{ modalOptions.icon }}
        </v-icon>
        {{ modalOptions.title }}
      </h5>
      <v-btn
        v-if="!modalOptions.submitting"
        icon
        x-small
        @click="$emit('modal-close')"
      >
        <v-icon size="24">$close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <div>
        <v-form ref="form" class="d-flex flex-column">
          <div
            v-for="(field, key) in modalOptions.formFields"
            :key="key"
            class="d-flex flex-column"
          >
            <label
              v-if="(field.label || field.tooltip) && field.type !== 'checkbox'"
              class="base--text mb-2"
              v-show="!field.hidden"
              >{{ field.label }}
            </label>

            <div
              v-if="field.type == 'password'"
              class="d-flex input-group"
              v-show="!field.hidden"
            >
              <v-text-field
                v-model="formValues[field.name]"
                outlined
                dense
                hide-details="auto"
                validate-on-blur
                :rules="field.rules"
                class="v-input--md"
                :type="field.showPassword ? 'text' : 'password'"
                :autofocus="false"
                autocomplete="new-password"
                v-show="!field.hidden"
              ></v-text-field>
              <v-btn
                icon
                tile
                outlined
                large
                color="gray"
                class="ml-4"
                @click="field.showPassword = !field.showPassword"
                v-show="!field.hidden"
              >
                <v-icon color="black"
                  >{{ field.showPassword ? "$eye" : "$eyeclosed" }}
                </v-icon>
              </v-btn>
            </div>

            <div
              :class="
                key + 1 === modalOptions.formFields.length ? 'last-child' : ''
              "
              v-else-if="field.type == 'email'"
              v-show="!field.hidden"
            >
              <v-text-field
                v-if="!field.loading"
                outlined
                :placeholder="field.placeholder"
                v-model="formValues[field.name]"
                class="v-input--md"
                hide-details="auto"
                validate-on-blur
                dense
                type="email"
                :rules="field.rules"
                :readonly="field.readonly"
                :prefix="field.prefix"
                :suffix="field.suffix"
              ></v-text-field>
              <v-skeleton-loader v-else type="text" />
            </div>

            <span v-if="field.description" class="mb-3"
              ><strong>{{ field.description }}</strong>
            </span>
          </div>
        </v-form>
      </div>
    </v-card-text>

    <v-card-actions
      :class="modalOptions.actionsClasses"
      class="d-flex flex-wrap pb-4"
    >
      <div
        class="w-100 pb-2"
        v-for="(button, key) in modalOptions.buttons"
        :key="key"
      >
        <v-btn
          :text="button.color == 'normal' ? true : false"
          large
          block
          elevation="0"
          :class="button.color"
          @click="!modalOptions.submitting && handleButtonClick(button)"
          :loading="
            (modalOptions.submitting || modalOptions.loading) &&
            button.label !== `Cancel`
          "
          :disabled="
            hasRequiredCheckboxes &&
            button.label !== 'Cancel' &&
            !formValues.confirm
          "
        >
          <template v-slot:loading>
            <loader />
          </template>
          <span
            class="p-2"
            :class="
              button.color == 'normal'
                ? 'font-weight-light gray--text text--darken-1'
                : 'font-weight-600'
            "
            >{{ button.label }}</span
          >
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import FormRulesMixin from "../../mixins/FormRulesMixin";

export default {
  mixins: [FormRulesMixin],
  data: function () {
    let formValues = { ...this.modalOptions.formValues };
    if (this.modalOptions.item) {
      formValues = { ...this.modalOptions.item };
    }
    for (const field of this.modalOptions.formFields) {
      if (typeof field.name === "undefined") {
        continue;
      }
      if (typeof formValues[field.name] === "undefined") {
        formValues[field.name] = null;
      }
    }
    return {
      formValues:formValues ,
      showPassword: false,
    };
  },
  props: {
    modalOptions: Object,
    instance: Object,
  },
  mounted() {
    if (this.modalOptions.onMount) {
      this.modalOptions.onMount();
    }
  },
  methods: {
    setValue(name, value) {
      this.formValues[name] = value;
    },
    setValues(values) {
      for (const value in values) {
        this.setValue(value, values[value]);
      }
    },
    showFields(names) {
      for (const field of this.modalOptions.formFields) {
        if (names.includes(field.name)) {
          field.hidden = false;
        }
      }
    },
    hideFields(names) {
      for (const field of this.modalOptions.formFields) {
        if (names.includes(field.name)) {
          field.hidden = true;
        }
      }
    },
    handleButtonClick(button) {
      button.onclick(this);
    },
    generateRandomString(chars, length) {
      let random = "";
      for (let i = 0; i < length; i++) {
        let randomNumber = Math.floor(Math.random() * chars.length);
        random += chars.substring(randomNumber, randomNumber + 1);
      }
      return random;
    },
  },
  beforeMount: function () {
    if (!this.color) {
      this.color = "primary";
    }
    if (!this.hasConfirmCheckbox) {
      this.hasConfirmCheckbox = false;
    }
  },
  computed: {
    hasRequiredCheckboxes: function () {
      const requiredCheckBoxes = this.modalOptions.formFields.filter((item) => {
        if (item.required && item.type == "checkbox") {
          return item;
        }
      });

      return requiredCheckBoxes.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-card {
    .v-card__actions {
      padding-top: 16px;
    }

    .v-card__title {
      min-height: 96px;
    }

    .v-card__text {
      -webkit-line-clamp: initial;
    }
  }
}

.checkbox::v-deep {
  .v-input--checkbox {
    .v-input__slot {
      max-height: 24px;
      min-height: unset;
    }
  }
}

.v-input::v-deep {
  .v-text-field__details {
    margin-top: 2px;
    margin-bottom: 0px !important;
  }
}

b {
  font-weight: $font-weight-semibold;
  color: map-get($text, heading);
}

.checkbox-label {
  cursor: pointer;
}

.input-group {
  .v-input + .v-input {
    margin-left: 16px;
  }
}

.v-form .last-child {
  .v-input {
    &.v-text-field {
      margin-bottom: 0px;
    }
  }
}

.v-form .v-input::v-deep.v-text-field {
  margin-bottom: 16px;
}

.v-card::v-deep {
  .v-card__title {
    flex-wrap: nowrap;

    .v-btn {
      align-self: flex-start;
    }
  }
}
</style>
